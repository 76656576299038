import React, { useState } from "react";
import Header from "../components/Header";

const CHristPage = () => {
  const [isOnLive, setIsOnLive] = useState(false);

  return (
    <div className="CHristPage">
      <Header currentPage="live" />

      <div className="h-screen bg-dark flex flex-col space-y-5 justify-center items-center text-center text-white uppercase font-bold px-4">
        {isOnLive ? (
          <div className="container xl:w-auto">
            <iframe className="w-full h-96 md:h-video-mini xl:w-video xl:h-video" src="https://www.youtube.com/embed/6ZJVLqz4q2M" title="From God Above - Hillsong UNITED" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        ) : (
          <div className="container mx-auto space-y-2">
            <h2 className="text-sm lg:text-base">There is no live now</h2>
            <h1 className="text-4xl lg:text-6xl">Please come back later</h1>
          </div>
        )}
      </div>
    </div>
  )
};

export default CHristPage;
