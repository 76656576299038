import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ContactPage = () => {
  return (
    <div className="ContactPage">
      <Header currentPage="contact" />

      <div className="relative h-screen bg-dark flex flex-col space-y-5 justify-center items-center text-center text-white uppercase font-bold px-4">
        <img className="absolute w-full h-full object-cover opacity-25" src="./images/yfc-contact.jpg" alt="" />
        <div className="z-30 container mx-auto sspace-y-2">
          <h2 className="text-sm lg:text-base">For more information</h2>
          <h1 className="text-4xl lg:text-6xl">Contact us</h1>
        </div>
        {/* <a href="/christ" className="btn bg-primary hover:bg-red-700 text-sm lg:text-base">Receive Jesus Now</a> */}
      </div>

      <div className="">
        {/* Write the code here to continue */}
        
      </div>
      
      { /*-- Newsletter Section --*/}
      <div className="bg-dark px-4 py-28">
        <div className="text-center text-white uppercase space-y-6">
          <h2 className="text-3xl lg:text-5xl font-bold">Stay tunned</h2>
          <div className="w-96 mx-auto px-8">
            <form className="space-y-1.5">
              <div className="space-y-2">
                <div className="flex gap-2">
                  <input className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" placeholder="Last Name" />
                  <input className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" placeholder="First Name" />
                </div>
                <input className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" placeholder="Email Address" />
                <textarea className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" rows="4" placeholder="Write your message here"></textarea>
              </div>
              <button className="btn bg-primary hover:bg-red-700 text-sm lg:text-base">Subscribe</button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
};

export default ContactPage;
