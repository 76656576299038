import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import SectionTItle from "../components/SectionTitle";

const EventPage = () => {
  const [isOnLive, setIsOnLive] = useState(true);

  return (
    <div className="EventPage">
      <Header currentPage="event" />

      <div className="relative h-screen bg-dark flex flex-col space-y-5 justify-center items-center text-center text-white uppercase font-bold px-4">
        <img className="absolute w-full h-full object-cover object-bottom opacity-25" src="./images/yfc-event.jpg" alt="" />
        <div className="z-30 container mx-auto sspace-y-2">
          <h2 className="text-sm lg:text-base">Discover</h2>
          <h1 className="text-4xl lg:text-6xl">Our events</h1>
        </div>
        {/* <a href="/christ" className="btn bg-primary hover:bg-red-700 text-sm lg:text-base">Receive Jesus Now</a> */}
      </div>

      <div className="my-20 space-y-20">
        {/*-- Live Section --*/}
        <div className="container mx-auto lg:px-4">
          <div className="bg-dark lg:rounded-2xl px-14 py-28">
            <div className="text-center text-white uppercase">
              {isOnLive ? (
                <div className="flex flex-col  space-y-6">
                  <p className="text-2xl lg:text-4xl font-bold">Go to live</p>
                  <div>
                    <NavLink to="/live" className="btn bg-primary hover:bg-red-700 text-sm lg:text-base">Live</NavLink>
                  </div>
                </div>
              ) : (
                <p className="text-2xl lg:text-4xl font-bold">Live soon</p>
              )}
            </div>
          </div>
        </div>

        { /*-- Cards of event Section --*/}
        <div className="container mx-auto px-4">
          <SectionTItle title="Events" />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="relative bg-dark rounded-xl w-full h-card overflow-hidden shadow-xl">
              <img className="absolute w-full h-full object-cover opacity-35" src="./images/event-1.jpg" alt="" />
              <div className="text-white w-full h-full flex flex-col justify-end">
                <div className="z-30 px-4 py-4 uppercase">
                  <h3 className="text-4xl font-semibold">Impact</h3>
                  <p className="text-xl">Be the impact of this world</p>
                </div>
              </div>
            </div>

            <div className="relative bg-dark rounded-xl w-full h-card overflow-hidden shadow-xl">
              <img className="absolute w-full h-full object-cover opacity-35" src="./images/event-2.jpg" alt="" />
              <div className="text-white w-full h-full flex flex-col justify-end">
                <div className="z-30 px-4 py-4 uppercase">
                  <h3 className="text-4xl font-semibold">Winner</h3>
                  <p className="text-xl">Ahoana no hahatonga anao ho mpandresy foana ?</p>
                </div>
              </div>
            </div>

            <div className="relative bg-dark rounded-xl w-full h-card overflow-hidden shadow-xl">
              <img className="absolute w-full h-full object-cover opacity-35" src="./images/event-3.jpg" alt="" />
              <div className="text-white w-full h-full flex flex-col justify-end">
                <div className="z-30 px-4 py-4 uppercase">
                  <h3 className="text-4xl font-semibold">Love</h3>
                  <p className="text-xl">Une relation intime</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      { /*-- Newsletter Section --*/}
      <div className="bg-dark px-4 py-28">
        <div className="text-center text-white space-y-6">
          <div className="space-y-2">
            <p className="text-sm lg:text-base">Subscribe and don't pass by any future event</p>
            <h2 className="text-3xl lg:text-5xl font-bold uppercase">Stay tunned</h2>
          </div>
          <div className="w-96 mx-auto px-8">
            <form className="space-y-4">
              <div className="space-y-2">
                <div className="flex gap-2">
                  <input className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" placeholder="Last Name" />
                  <input className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" placeholder="First Name" />
                </div>
                <input className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" placeholder="Email Address" />
                {/* <textarea className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" rows="4" placeholder="Write your message here"></textarea> */}
              </div>
              {/* <button className="btn bg-primary hover:bg-red-700 text-sm lg:text-base">Subscribe</button> */}
            </form>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  )
};

export default EventPage;
