import React from "react";
import { NavLink } from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { PiLinktreeLogoBold } from "react-icons/pi";

const Footer = () => {
  return (
    <footer className="Footer bg-dark text-white py-8">
      <div className="container mx-auto w-full px-4">
        <div className="grid grid-cols-11 gap-4">
          <div className="col-span-11 lg:col-span-3 space-y-2">
            <div className="flex">
              <NavLink to="/">
                <h1 className="text-2xl font-semibold">Young For Christ</h1>
              </NavLink>
            </div>
            <p>Your life can change through the power of prayer and the great salvation given by Jesus Christ.</p>
          </div>
          <div className="hidden lg:block col-span-2"></div>
          <div className="col-span-11 lg:col-span-3 space-y-2">
            <h3 className="text-2xl font-semibold">Locations</h3>
            <p>Antananarivo</p>
            <p>Antsirabe</p>
          </div>
          <div className="col-span-11 lg:col-span-3 space-y-2">
            <h3 className="text-2xl font-semibold">Contacts</h3>
            <p>+261 38 71 613 53</p>
            <p>yfcyoungforchrist@gmail.com</p>
          </div>
        </div>
        <hr className="mt-8 mb-6" />
        <div className="flex justify-between items-center space-x-8">
          <p>Copyright &copy; 2024 All rights reserved. Young For Christ.</p>
          <div className="flex justify-end items-center gap-4">
            <a className="hover:opacity-50 transition ease-linear duration-100" href="https://www.facebook.com/profile.php?id=61551624501516" target="_blank" rel="noreferrer"><FaFacebook /></a>
            <a className="hover:opacity-50 transition ease-linear duration-100" href="https://www.instagram.com/s.youngforchrist/" target="_blank" rel="noreferrer"><FaInstagram /></a>
            <a className="hover:opacity-50 transition ease-linear duration-100" href="https://linktr.ee/youngforchrist" target="_blank" rel="noreferrer"><PiLinktreeLogoBold /></a>
          </div>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
