export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const separateNumbers = (number) => {
  let numberString = number.toString();
  let separatedNumber = '';

  let count = 0;
  for (let i = numberString.length - 1; i >= 0; i--) {
    separatedNumber = numberString[i] + separatedNumber;
    count++;

    if (count === 3 && i > 0) {
      separatedNumber = ' ' + separatedNumber;
      count = 0;
    }
  }

  return separatedNumber;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}
