import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import { toast, ToastContainer } from "react-toastify";
import { isEmpty } from "../../Utils";
import { loginUserRequest, storeToken } from "../../services/AuthenticationService";

const LoginPage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  
  useEffect(() => {
    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await loginUserRequest(login, password);

    if (response.status === "success") {
      storeToken(response.data.access, response.data.refresh);
      navigate("/profile", {
        state: {
          status: "success",
          message: response.message,
        }
      });
    } else if (response.status === "warning") {
      setLoading(false);
      toast.warning(response.message);
    } else {
      setLoading(false);
      toast.error(response.message);
    }
  };

  return (
    <div className="LoginPage">
      <ToastContainer />
      
      {loading ? (
        <div className="h-screen flex justify-center items-center">
          <TailSpin
            visible={true}
            height={85}
            width={85}
            color="#D14124"
            ariaLabel="tail-spin-loading"
            radius={1}
            strokeWidth={4}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="h-screen flex justify-center items-center py-6">
          <div className="w-full max-w-xl px-4">
            <div className="bg-white space-y-8 p-8 sm:p-16 rounded-3xl shadow-2xl border-primary border">
              <div className="flex justify-center">
                <h1 className="font-bold text-gray-700 uppercase text-4xl text-center">Young For Christ</h1>
              </div>
              <div className="space-y-2">
                <h1 className="text-center text-gray-700 text-4xl font-semibold">Log in</h1>
                <p className="text-center text-gray-700">Please authenticate yourself</p>
              </div>
              <form className="flex flex-col space-y-6" onSubmit={(e) => handleFormSubmit(e)}>
                <div className="flex flex-col space-y-2">
                  <label className="font-semibold" htmlFor="login">Email or Phone</label>
                  <input id="login" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" type="text" name="login" placeholder="Email address or Phone number" required value={login} onChange={(e) => setLogin(e.target.value)} />
                </div>
                <div className="flex flex-col space-y-2">
                  <label className="font-semibold" htmlFor="password">Password</label>
                  <input id="password" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" type="password" name="password" placeholder="Password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                {/* <div className="flex justify-between">
                  <NavLink className="text-sm text-tertiary-100 hover:opacity-75 transition ease-linear duration-100 text-gray-600" to="">Forgot your password ?</NavLink>
                </div> */}
                <button className="btn bg-primary hover:opacity-90 font-semibold text-white" type="submit">Log in</button>
                <p className="text-sm text-center">Don't have an account ? <NavLink className="text-sky-600 hover:opacity-75 transition ease-linear duration-100" to="/signup">Sign up</NavLink></p>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginPage;
