import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SectionTItle from "../components/SectionTitle";
import { NavLink } from "react-router-dom";

const AboutPage = () => {
  return (
    <div className="AboutPage">
      <Header currentPage="about" />

      <div className="relative h-screen bg-dark flex flex-col justify-center items-center text-center text-white uppercase font-bold px-4 space-y-5">
        <img className="absolute w-full h-full object-cover opacity-25" src="./images/yfc-about.jpg" alt="" />
        <div className="z-30 container mx-auto space-y-2">
          <h2 className="text-sm lg:text-base">Lover of Christ</h2>
          <h1 className="text-4xl lg:text-6xl">About us</h1>
        </div>
        {/* <a href="/christ" className="btn bg-primary hover:bg-red-700 text-sm lg:text-base">Receive Jesus Now</a> */}
      </div>

      {/*-- Intro Section --*/}
      <div className="mt-32 mb-20 space-y-20">
        <div className="container mx-auto px-4 max-w-2xl">
          {/* <div className="absolute inset-x-0 -top-40 -z-10 overflow-hidden blur-3xl sm:-top-80">
            <div className="relative left-[calc(50%-11rem)] aspect-w-1155 aspect-h-678 w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-75 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }}>
            </div>
          </div> */}
          <div className="text-center space-y-9">
            {/* <SectionTItle title="YFC" /> */}
            <h2 className="text-3xl lg:text-5xl font-bold tracking-tight text-gray-900">
              Young For Christ
            </h2>
            <div className="space-y-6">
              <p className="text-xl text-gray-700 mt-4 font-semibold">Created on 10th October 2023</p>
              <p className="text-lg leading-8 text-gray-600 italic">
                « Your life can change through the power of prayer and the great salvation given by Jesus Christ. »
              </p>
            </div>
          </div>
          {/* <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
            <div className="relative left-[calc(50%+3rem)] aspect-w-1155 aspect-h-678 w-[36.125rem] -translate-x-1/2 bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }}>
            </div>
          </div> */}
        </div>

        {/*-- Mission Section --*/}
        <div className="container mx-auto px-4 max-w-xl">
          <SectionTItle title="We are" />
          <div>
            <ul className="text-center text-xl text-gray-700 font-semibold space-y-6">
              <li>A church for young people</li>
              <li>Preaching the gospel to young people in all the world between 16 and 42 years</li>
            </ul>
          </div>
          {/* <div className="flex items-stretch mb-24 mt-24 ml-44">
            <div className="columns-2xs">
              <img className="w-full aspect-video rounded-lg object-cover" src="img/vazah.jpg" alt="vazah" />
              <img className="w-full aspect-square rounded-lg object-cover mt-5" src="img/rhv.jpg" alt="rhv" />
              <img className="w-full aspect-video rounded-lg object-cover" src="img/rhl.jpg" alt="rhl" />
              <img className="w-full aspect-square rounded-lg object-cover mt-5" src="img/pst.jpg" alt="pst" />
            </div>
            <div className="mt-28 ml-24">
              <p className="mt-10 leading-8">Attract people to become people of prayer: so that he knows his creator.
                <br />
                Uplift people, especially young people.
                <br />
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex nisi quidem velit ratione asperiores obcaecati, accusamus quasi nesciunt unde ipsam? Ratione sed dolor expedita ullam harum earum, dolore omnis consequatur!
                <br />
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Est consequuntur quasi minima id, eveniet sapiente distinctio, nam impedit nemo incidunt sint, quisquam minus pariatur consectetur magnam cumque. Quasi, qui enim!
              </p>
            </div>
          </div> */}
        </div>

        {/* <div>
          <svg className="animate-bounce w-6 h-6"></svg>
        </div> */}

        {/* <div className="bg-no-repeat bg-center bg-fixed" style={{ backgroundImage: 'url(img/rex.jpg)' }}>
          <h1 className="p-80 mt-24 text-4xl font-bold tracking-tight text-cyan-50 sm:text-6xl text-center hover:text-zinc-200">
            Preaching the gospel to young people in all the world
          </h1>
        </div> */}

        {/*-- Tour Section --*/}
        <div className="container mx-auto px-4">
          <SectionTItle title="Tours" />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="h-96 rounded-xl bg-dark shadow-lg">
              <div className="w-full h-full relative overflow-hidden">
                <div className="w-full h-full text-white absolute flex flex-col justify-center items-center">
                  <h5 className="font-semibold text-3xl lg:text-5xl uppercase">Antsirabe</h5>
                  <p className="text-lg">23rd March 2024</p>
                </div>
                <img className="w-full h-full object-cover opacity-0 hover:opacity-100 -rotate-12 hover:rotate-0 scale-125 hover:scale-150 transition ease-linear duration-100" src="./images/yfc-tour-antsirabe.png" alt="" />
              </div>
            </div>
            <div className="h-96 rounded-xl bg-dark shadow-lg">
              <div className="w-full h-full relative overflow-hidden">
                <div className="w-full h-full text-white absolute flex flex-col justify-center items-center">
                  <h5 className="font-semibold text-3xl lg:text-5xl uppercase">Antananarivo</h5>
                  <p className="text-lg">27th July 2024</p>
                </div>
                <img className="w-full h-full object-cover opacity-0 hover:opacity-100 -rotate-12 hover:rotate-0 scale-125 hover:scale-150 transition ease-linear duration-100" src="./images/yfc-tour-tana.jpg" alt="" />
              </div>
            </div>
            <div className="h-96 rounded-xl bg-dark shadow-lg">
              <div className="w-full h-full relative overflow-hidden">
                <div className="w-full h-full text-white absolute flex flex-col justify-center items-center">
                  <h5 className="font-semibold text-3xl lg:text-5xl uppercase">Tamatave</h5>
                  <p className="text-lg">Coming soon</p>
                </div>
                <img className="w-full h-full object-cover opacity-0 hover:opacity-100 -rotate-12 hover:rotate-0 scale-125 hover:scale-150 transition ease-linear duration-100" src="" alt="" />
              </div>
            </div>
            <div className="h-96 rounded-xl bg-dark shadow-lg">
              <div className="w-full h-full relative overflow-hidden">
                <div className="w-full h-full text-white absolute flex flex-col justify-center items-center">
                  <h5 className="font-semibold text-3xl lg:text-5xl uppercase">Majunga</h5>
                  <p className="text-lg">Coming soon</p>
                </div>
                <img className="w-full h-full object-cover opacity-0 hover:opacity-100 -rotate-12 hover:rotate-0 scale-125 hover:scale-150 transition ease-linear duration-100" src="" alt="" />
              </div>
            </div>
            <div className="h-96 rounded-xl bg-dark shadow-lg">
              <div className="w-full h-full relative overflow-hidden">
                <div className="w-full h-full text-white absolute flex flex-col justify-center items-center">
                  <h5 className="font-semibold text-3xl lg:text-5xl uppercase">Fianarantsoa</h5>
                  <p className="text-lg">Coming soon</p>
                </div>
                <img className="w-full h-full object-cover opacity-0 hover:opacity-100 -rotate-12 hover:rotate-0 scale-125 hover:scale-150 transition ease-linear duration-100" src="" alt="" />
              </div>
            </div>
            <div className="h-96 rounded-xl bg-dark shadow-lg">
              <div className="w-full h-full relative overflow-hidden">
                <div className="w-full h-full text-white absolute flex flex-col justify-center items-center">
                  <h5 className="font-semibold text-3xl lg:text-5xl uppercase">Diego</h5>
                  <p className="text-lg">Coming soon</p>
                </div>
                <img className="w-full h-full object-cover opacity-0 hover:opacity-100 -rotate-12 hover:rotate-0 scale-125 hover:scale-150 transition ease-linear duration-100" src="" alt="" />
              </div>
            </div>
          </div>
        </div>

        {/*-- Family Section --*/}
        <div className="container mx-auto px-4 space-y-16">
          <h2 className="text-gray-800 font-bold text-2xl sm:text-4xl text-center uppercase">We are a family</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <img className="w-full h-full aspect-square object-cover rounded-lg" src="images/family-1.jpg" alt="" />
            <img className="w-full h-full aspect-square object-cover rounded-lg" src="images/family-2.jpg" alt="" />
            <img className="w-full h-full aspect-square object-cover rounded-lg" src="images/family-3.jpg" alt="" />
            <img className="w-full h-full aspect-square object-cover rounded-lg" src="images/family-4.jpg" alt="" />
            <img className="w-full h-full aspect-square object-cover rounded-lg" src="images/family-5.jpg" alt="" />
            <img className="w-full h-full aspect-square object-cover rounded-lg" src="images/family-6.jpg" alt="" />
          </div>
        </div>

        {/*-- Join Section --*/}
        <div className="container mx-auto px-4">
          <SectionTItle title="Join" />
          <div className="bg-dark lg:rounded-2xl px-14 py-28">
            <div className="text-center text-white uppercase flex flex-col space-y-6">
              <p className="text-gray-200 text-xl lg:text-2xl font-semibold">
                Want to live an amazing life with Christ ?
              </p>
              <div>
                <NavLink to="/join" className="btn bg-primary hover:bg-red-700 text-sm lg:text-base font-semibold">Join us</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutPage;
