import axios from "axios";
import camelize from "camelize";
import { getApiUri, getHeaders } from "./NetworkService";
import { isEmpty } from "../Utils";

export const getToken = () => {
  if (isEmpty(localStorage.getItem("token"))) {
    return null;
  }
  return "EVG " + localStorage.getItem("token");
};

export const storeToken = (access, refresh) => {
  localStorage.setItem("token", access);
  localStorage.setItem("refresh", refresh);
};

export const refreshTokenRequest = async () => {
  const uri = getApiUri();

  const refresh = localStorage.getItem("refresh");

  const data = {
    refresh: refresh,
  };

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "POST", url: `${uri}/api/v1/auth/jwt/refresh/`, data: data })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.message = "Token refreshed";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Refreshing token impossible";
      }
    });

  return responseData;
}

export const signupUserRequest = async ( firstName, lastName, alias, phone, email, password ) => {
  const uri = getApiUri();

  const data = {
    first_name: firstName,
    last_name: lastName,
    alias: alias,
    phone: phone,
    email: email,
    password: password,
  };

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "POST", url: `${uri}/api/v1/auth/users/`, data: data })
    .then((response) => {
      if (response.status === 201) {
        responseData.status = "success";
        responseData.message = "Account created successfuly";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "An error has been encountered";
      }
    }).catch((error) => {
      if (!isEmpty(error.response.data.email)) {
        responseData.status = "warning";
        responseData.message = "An account has been linked to this email address";
      } else {
        if (error.message === "Network Error") {
          responseData.status = "error";
          responseData.message = "The backend is not yet ready";
        } else {
          responseData.status = "error";
          responseData.message = "Please try later";
        }
      }
    });

  return responseData;
};

export const loginUserRequest = async ( login, password ) => {
  const uri = getApiUri();

  const data = {
    phone: login,
    password: password,
  };

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "POST", url: `${uri}/api/v1/auth/jwt/create/`, data: data })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.message = "Connected successfuly";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "An error has been encountered";
      }
    }).catch((error) => {
      if (error.message === "Network Error") {
        responseData.status = "error";
        responseData.message = "The backend is not yet ready";
      } else {
        responseData.status = "warning";
        responseData.message = "Please verify your information";
      }
    });

  return responseData;
};

export const logoutUserRequest = async () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");
}

export const getCurrentUserRequest = async () => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/users/me/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Error when getting the user's information";
      }
    }).catch((error) => {
      if (error.message === "Network Error") {
        responseData.status = "error";
        responseData.message = "The backend is not yet ready";
      } else {
        responseData.status = "error";
        responseData.message = "Error when getting the user's information";
      }
    });

  return responseData;
};
