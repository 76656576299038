import React from "react";

const NotFoundPage = () => {
  return (
    <div className="NotFoundPage">
      <div className="h-screen bg-dark flex flex-col justify-around items-center text-center text-white px-4">
        <div></div>
        <div className="flex flex-col space-y-5">
          <h1 className="text-8xl lg:text-9xl font-semibold">404</h1>
          <h2 className="text-3xl">SORRY !</h2>
          <h3 className="text-lg">The page you’re looking for was not found.</h3>
          <a className="btn bg-primary hover:bg-red-700" href="/">Back to home</a>
        </div>
        <p>Copyright &copy; 2024 All rights reserved. Young For Christ.</p>
      </div>
    </div>
  )
};

export default NotFoundPage;
