import React from "react";

const SectionTItle = ({ title }) => {
  return (
    <div className="SectionTItle">
      <div className="my-24 text-center">
        <div className="relative">
          <h3 className="z-0 absolute w-full text-center text-7xl lg:text-9xl text-gray-600 lg:text-gray-700 opacity-5 -mt-10 lg:-mt-14">{title}</h3>
          <h2 className="z-30 text-gray-500 text-5xl">{title}</h2>
        </div>
      </div>
    </div>
  )
};

export default SectionTItle;
