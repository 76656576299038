import React from 'react';

const Countdown = ({ timeLeft }) => {

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{' '}
      </span>
    );
  });

  return (
    <div className="Countdown flex justify-center">
      <div className="bg-primary hover:bg-red-700 rounded-full px-6 lg:px-16 pt-3 pb-4 lg:pb-6 transition ease-linear duration-100">
        {timerComponents.length ? (
          <div className="text-5xl lg:text-7xl">
            {timeLeft.days} : {timeLeft.hours} : {timeLeft.minutes}  : {timeLeft.seconds}
          </div>
        ) : (
          <span>Ready !</span>
        )}
      </div>
    </div>
  )
};

export default Countdown;
