import React, { useState } from "react";
import Header from "../components/Header";
import SectionTItle from "../components/SectionTitle";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";

const HomePage = () => {
  const [isOnLive, setIsOnLive] = useState(false);

  return (
    <div className="HomePage">
      <Header currentPage="home" />

      <div className="relative h-screen bg-dark flex flex-col space-y-5 justify-center items-center text-center text-white uppercase font-bold px-4">
        <video className="absolute w-full h-full object-cover opacity-25" src="./videos/yfc-croisade-antsirabe.mp4" autoPlay={true} muted loop />
        <div className="z-30 container mx-auto space-y-2">
          <h2 className="text-sm lg:text-base">We are young</h2>
          <h1 className="text-4xl lg:text-6xl">We live for Christ</h1>
        </div>
        <a href="/christ" className="z-30 btn bg-primary hover:bg-red-700 text-sm lg:text-base">Receive Jesus Now</a>
      </div>

      {isOnLive && (
        <div className="h-screen bg-dark flex flex-col space-y-5 justify-center items-center text-center text-white uppercase font-bold px-4">
          <div className="container xl:w-auto">
            <iframe className="w-full h-96 md:h-video-mini xl:w-video xl:h-video" src="https://www.youtube.com/embed/6ZJVLqz4q2M" title="From God Above - Hillsong UNITED" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
      )}

      <div className="mt-12 space-y-20">
        <div className="container mx-auto">
          <SectionTItle title="Linktree" />
          <div className="text-center">
            <a href="https://linktr.ee/youngforchrist" target="_blank" rel="noreferrer" className="btn bg-sky-600 hover:bg-sky-700 text-white text-sm lg:text-base">Our links</a>
          </div>
        </div>

        { /*-- Verse Section --*/ }
        <div className="container mx-auto lg:px-4">
          <div className="bg-dark lg:rounded-2xl px-14 py-28">
            <div className="text-center text-white uppercase space-y-6">
              <h2 className="text-4xl lg:text-6xl font-bold">PSALM 23 : 6</h2>
              <p className="text-gray-200 text-xl lg:text-2xl font-semibold">
                Surely goodness and mercy shall follow me
                All the days of my life;<br/>
                And I will dwell in the house of the LORD
                Forever.
              </p>
            </div>
          </div>
        </div>

        { /*-- Links Section --*/ }
        <div className="container mx-auto px-4">
          <SectionTItle title="Links" />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <NavLink to="/about" className="h-96 rounded-xl bg-dark shadow-lg">
              <div className="w-full h-full relative overflow-hidden">
                <div className="w-full h-full text-white absolute flex flex-col justify-center items-center">
                  <h5 className="font-semibold text-4xl lg:text-6xl uppercase">About</h5>
                  <p className="text-lg">Discover us</p>
                </div>
                <img className="w-full h-full object-cover opacity-25 hover:opacity-10 hover:scale-125 transition ease-linear duration-100" src="./images/yfc-about-link.jpg" alt="" />
              </div>
            </NavLink>

            <NavLink to="/event" className="h-96 rounded-xl bg-dark shadow-lg">
              <div className="w-full h-full relative overflow-hidden">
                <div className="w-full h-full text-white absolute flex flex-col justify-center items-center">
                  <h5 className="font-semibold text-4xl lg:text-6xl uppercase">Event</h5>
                  <p className="text-lg">Don't miss any event</p>
                </div>
                <img className="w-full h-full object-cover opacity-25 hover:opacity-10 hover:scale-125 transition ease-linear duration-100" src="./images/yfc-event-link.jpg" alt="" />
              </div>
            </NavLink>

            <NavLink to="/contact" className="h-96 rounded-xl bg-dark shadow-lg">
              <div className="w-full h-full relative overflow-hidden">
                <div className="w-full h-full text-white absolute flex flex-col justify-center items-center">
                  <h5 className="font-semibold text-4xl lg:text-6xl uppercase">Contact</h5>
                  <p className="text-lg">Connect to us</p>
                </div>
                <img className="w-full h-full object-cover opacity-25 hover:opacity-10 hover:scale-125 transition ease-linear duration-100" src="./images/yfc-contact-link.jpg" alt="" />
              </div>
            </NavLink>

            <NavLink to="/christ" className="h-96 rounded-xl bg-dark shadow-lg">
              <div className="w-full h-full relative overflow-hidden">
                <div className="w-full h-full text-white absolute flex flex-col justify-center items-center">
                  <h5 className="font-semibold text-4xl lg:text-6xl uppercase">Christ</h5>
                  <p className="text-lg">Our King and Saviour</p>
                </div>
                <img className="w-full h-full object-cover opacity-25 hover:opacity-10 hover:scale-125 transition ease-linear duration-100" src="./images/cross.webp" alt="" />
              </div>
            </NavLink>

            <NavLink to="/give" className="h-96 rounded-xl bg-dark shadow-lg">
              <div className="w-full h-full relative overflow-hidden">
                <div className="w-full h-full text-white absolute flex flex-col justify-center items-center">
                  <h5 className="font-semibold text-4xl lg:text-6xl uppercase">Give</h5>
                  <p className="text-lg">Whatever you want</p>
                </div>
                <img className="w-full h-full object-cover opacity-25 hover:opacity-10 hover:scale-125 transition ease-linear duration-100" src="./images/yfc-give-link.jpg" alt="" />
              </div>
            </NavLink>

            <NavLink to="/join" className="h-96 rounded-xl bg-dark shadow-lg">
              <div className="w-full h-full relative overflow-hidden">
                <div className="w-full h-full text-white absolute flex flex-col justify-center items-center">
                  <h5 className="font-semibold text-4xl lg:text-6xl uppercase">Join</h5>
                  <p className="text-lg">Join us now</p>
                </div>
                <img className="w-full h-full object-cover opacity-25 hover:opacity-10 hover:scale-125 transition ease-linear duration-100" src="./images/yfc-join-link.jpg" alt="" />
              </div>
            </NavLink>
          </div>
        </div>

        { /*-- Oh Yes --*/}
        <div className="bg-dark">
          <div className="relative h-screen flex flex-col space-y-5 justify-center items-center text-center text-white uppercase font-bold px-4">
            <img className="absolute w-full h-full object-cover opacity-25" src="./images/oh-yes.jpg" alt="" />
            <div className="container mx-auto space-y-3.5">
              <h2 className="text-4xl lg:text-6xl">Oh Yes</h2>
              <p className="text-xl lg:text-2xl">You can be the impact of this world</p>
            </div>
            <NavLink to="/join" className="btn bg-primary hover:bg-red-700 text-sm lg:text-base">Join us</NavLink>
          </div>
        </div>
      </div>

      { /*-- Newsletter Section --*/}
      <div className="bg-dark px-4 py-28">
        <div className="text-center text-white uppercase space-y-6">
          <h2 className="text-3xl lg:text-5xl font-bold">Stay tunned</h2>
          <div className="w-96 mx-auto px-8">
            <form className="space-y-4">
              <div className="space-y-2">
                <div className="flex gap-2">
                  <input className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" placeholder="Last Name" />
                  <input className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" placeholder="First Name" />
                </div>
                <input className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" placeholder="Email Address" />
                {/* <textarea className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" rows="4" placeholder="Write your message here"></textarea> */}
              </div>
              {/* <button className="btn bg-primary hover:bg-red-700 text-sm lg:text-base">Subscribe</button> */}
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
};

export default HomePage;
