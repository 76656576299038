import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const GivePage = () => {
  return (
    <div className="GivePage">
      <Header currentPage="give" />

      <div className="h-screen bg-dark flex flex-col space-y-5 justify-center items-center text-center text-white uppercase font-bold px-4">
        <img className="absolute inset-0 w-full h-full object-cover opacity-25" src="./images/yfc-join.jpg" alt="" />
        <div className="z-30 container mx-auto sspace-y-2">
          <h2 className="text-sm lg:text-base">Give what you want</h2>
          <h1 className="text-4xl lg:text-6xl">Support us</h1>
        </div>
        {/* <a href="/christ" className="btn bg-primary hover:bg-red-700 text-sm lg:text-base">Receive Jesus Now</a> */}
      </div>

      <div className="">
        {/* Write the code here to continue */}
        
      </div>
      
      <Footer />
    </div>
  )
};

export default GivePage;
