import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import { toast, ToastContainer } from "react-toastify";
import { signupUserRequest } from "../../services/AuthenticationService";

const SignupPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [alias, setAlias] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== passwordConfirmation) {
      return toast.error("Passwords are not the same");
    }

    const response = await signupUserRequest(firstName, lastName, alias, phone, email, password);
    
    if (response.status === "success") {
      navigate("/login/", {
        state: {
          status: "success",
          message: response.message,
        }
      });
    } else if (response.status === "warning") {
      navigate("/login/", {
        state: {
          status: "warning",
          message: response.message,
        }
      });
    } else {
      setLoading(false);
      toast.error(response.message);
    }
  };

  return (
    <div className="SignupPage">
      <ToastContainer />
      
      {loading ? (
        <div className="h-screen flex justify-center items-center">
          <TailSpin
            visible={true}
            height={85}
            width={85}
            color="#D14124"
            ariaLabel="tail-spin-loading"
            radius={1}
            strokeWidth={4}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="flex justify-center items-center py-6">
          <div className="w-full max-w-xl px-4">
            <div className="bg-white space-y-8 p-8 sm:p-16 rounded-3xl shadow-2xl border-primary border">
              <div className="flex justify-center">
                <h1 className="font-bold text-gray-700 uppercase text-4xl text-center">Young For Christ</h1>
              </div>
              <div className="space-y-2">
                <h1 className="text-center text-gray-700 text-4xl font-semibold">Registration</h1>
                <p className="text-center text-gray-700">Please make sure you are a real member</p>
              </div>
              <form className="flex flex-col space-y-6" onSubmit={(e) => handleFormSubmit(e)}>
                <div className="flex flex-col space-y-2">
                  <label className="font-semibold" htmlFor="firstName">First name</label>
                  <input id="firstName" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" type="text" name="firstName" placeholder="First name"required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </div>
                <div className="flex flex-col space-y-2">
                  <label className="font-semibold" htmlFor="lastName">Last name</label>
                  <input id="lastName" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" type="text" name="lastName" placeholder="Last name" required value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </div>
                <div className="flex flex-col space-y-2">
                  <label className="font-semibold" htmlFor="alias">Alias</label>
                  <input id="alias" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" type="text" name="alias" placeholder="Eg : Rl Tovo" required value={alias} onChange={(e) => setAlias(e.target.value)} />
                </div>
                <div className="flex flex-col space-y-2">
                  <label className="font-semibold" htmlFor="phone">Phone</label>
                  <input id="phone" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" type="tel" name="phone" placeholder="Phone number" required value={phone} onChange={(e) => setPhone(e.target.value)} />
                </div>
                <div className="flex flex-col space-y-2">
                  <label className="font-semibold" htmlFor="email">Email (optional)</label>
                  <input id="email" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" type="email" name="text" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="flex flex-col space-y-2">
                  <label className="font-semibold" htmlFor="password">Password</label>
                  <input id="password" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" type="password" name="password" placeholder="Password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="flex flex-col space-y-2">
                  <label className="font-semibold" htmlFor="password">Password Confirmation</label>
                  <input id="password" className="px-4 py-3 h-11 border text-sm text-gray-700 bg-gray-200 border-none rounded-md shadow-sm focus:outline-none focus:border-gray-200 focus:ring-1 focus:ring-gray-200" type="password" name="passwordConfirmation" placeholder="Re-type password" required value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
                </div>
                <button className="btn bg-primary hover:opacity-90 font-semibold text-white" type="submit">Create an account</button>
                <p className="text-sm text-center">Already have an account ? <NavLink className="text-sky-600 hover:opacity-75 transition ease-linear duration-100" to="/login">Log in</NavLink></p>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignupPage;
