import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const CHristPage = () => {
  return (
    <div className="CHristPage">
      <Header currentPage="christ" />

      <div className="relative h-screen bg-dark flex flex-col space-y-5 justify-center items-center text-center text-white uppercase font-bold px-4">
        <img className="absolute w-full h-full object-cover opacity-25" src="./images/cross.webp" alt="" />
        <div className="z-30 container mx-auto sspace-y-2">
          <h2 className="text-sm lg:text-base">Our saviour</h2>
          <h1 className="text-4xl lg:text-6xl">Jesus Christ</h1>
        </div>
        {/* <a href="/christ" className="btn bg-primary hover:bg-red-700 text-sm lg:text-base">Receive Jesus Now</a> */}
      </div>

      <div className="my-20 space-y-20">
        { /*-- Verse Section --*/ }
        <div className="container mx-auto lg:px-4">
          <div className="bg-dark lg:rounded-2xl px-14 lg:px-48 py-28">
            <div className="text-center text-white uppercase space-y-6">
              <h2 className="text-4xl lg:text-6xl font-bold">JOHN 3 : 16</h2>
              <p className="text-gray-200 text-xl lg:text-2xl font-semibold">
                For God so loved the world that He gave His only begotten Son, that whoever believes in Him should not perish but have everlasting life.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-dark py-20 lg:py-32 text-white">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-5 gap-12">
              <div className="col-span-5 lg:col-span-2 space-y-4 sticky top-1/3 h-screen">
                <h2 className="text-4xl font-semibold uppercase">Give your life <strong className="text-primary font-bold">to Christ</strong> like them</h2>
                <p className="text-xl font-semibold">Jesus should be the center of your life. Don't live to die, live to be <strong className="text-primary font-bold">the impact</strong> of this world.</p>
              </div>
              <div className="col-span-5 lg:col-span-3">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-12 lg:gap-y-0">
                  <img className="w-full h-card object-cover hover:border-8 border-primary hover:scale-105 transition ease-linear duration-100" src="./images/young-impact-1.jpg" alt="" />
                  <img className="lg:mt-24 w-full h-card object-cover hover:border-8 border-primary hover:scale-105 transition ease-linear duration-100" src="./images/young-impact-2.jpg" alt="" />
                  <img className="lg:-mt-12 w-full h-card object-cover hover:border-8 border-primary hover:scale-105 transition ease-linear duration-100" src="./images/young-impact-3.jpg" alt="" />
                  <img className="lg:mt-12 w-full h-card object-cover hover:border-8 border-primary hover:scale-105 transition ease-linear duration-100" src="./images/young-impact-4.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto space-y-8">
          <div className="text-center space-y-4">
            <h2 className="text-4xl font-semibold">Do this prayer</h2>
            <p className="text-lg">
              Jesus I'm sorry for the mistakes I've made and the sins I've committed<br/>
              I admit that I'm a sinner<br/>
              But I believe in You Jesus<br/>
              Please forgive me<br/>
              I believe that you died for me on that cross<br/>
              And rose up to give me life<br/>
              Here I am Jesus, please receive me<br/>
              I give to You my everything<br/>
              I accept you as my King and Saviour<br/>
              From now until the end<br/>
              Thank You Jesus ! Amen
            </p>
          </div>

          <div className="text-center space-y-4">
            <h2 className="text-4xl font-semibold">Or contact us</h2>
            <p className="text-lg">
              +261 38 71 613 53<br />
              yfcyoungforchrist@gmail.com
            </p>
          </div>
        </div>
      </div>

      { /*-- Newsletter Section --*/}
      <div className="bg-dark px-4 py-28">
        <div className="text-center text-white uppercase space-y-6">
          <p className="text-sm lg:text-base">We want to help you to know more about Christ</p>
          <h2 className="text-3xl lg:text-5xl font-bold">Let's connect</h2>
          <div className="w-96 mx-auto px-8">
            <form className="space-y-4">
              <div className="space-y-2">
                <div className="flex gap-2">
                  <input className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" placeholder="Last Name" />
                  <input className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" placeholder="First Name" />
                </div>
                <input className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" placeholder="Email Address" />
                {/* <textarea className="w-full rounded px-4 py-2 bg-gray-600 border-0 placeholder-gray-300" rows="4" placeholder="Write your message here"></textarea> */}
              </div>
              {/* <button className="btn bg-primary hover:bg-red-700 text-sm lg:text-base">Subscribe</button> */}
            </form>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  )
};

export default CHristPage;
