import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const ProfilePage = () => {
  const [currentPage, setCurrentPage] = useState("information");

  return (
    <div className="ProfilePage">
      <div className="h-screen mx-16 py-12 shadow-2xl">
        <div className="grid grid-cols-6">
          <div className="col-span-1 flex flex-col border-r border-primary px-4">
            <button className="btn-full hover:bg-sky-600 hover:text-white text-start">Informations personnelles</button>
            <button className="btn-full hover:bg-sky-600 hover:text-white text-start">Badge</button>
            <NavLink className="btn-full hover:bg-sky-600 hover:text-white text-start" to="/">Retour vers le site</NavLink>
            <button className="btn-full hover:bg-secondary hover:text-white text-start">Déconnexion</button>
          </div>
          <div className="col-span-5">
            <div className="px-16 py-4 grid grid-cols-2 gap-x-8 gap-y-6">
              <div className="space-y-2">
                <h3 className="text-base font-semibold">Titre</h3>
                <p className="text-sm bg-gray-100 px-4 py-2 rounded-lg font-thin">EP</p>
              </div>
              <div className="space-y-2">
                <h3 className="text-base font-semibold">Nom de profil</h3>
                <p className="text-sm bg-gray-100 px-4 py-2 rounded-lg font-thin">Rl Christian</p>
              </div>
              <div className="space-y-2">
                <h3 className="text-base font-semibold">Nom</h3>
                <p className="text-sm bg-gray-100 px-4 py-2 rounded-lg font-thin">RAZAKAMAHERY</p>
              </div>
              <div className="space-y-2">
                <h3 className="text-base font-semibold">Prénom(s)</h3>
                <p className="text-sm bg-gray-100 px-4 py-2 rounded-lg font-thin">Nomena Christian</p>
              </div>
              <div className="space-y-2">
                <h3 className="text-base font-semibold">Adresse e-mail</h3>
                <p className="text-sm bg-gray-100 px-4 py-2 rounded-lg font-thin">christiangrzk@gmail.com</p>
              </div>
              <div className="space-y-2">
                <h3 className="text-base font-semibold">Téléphone</h3>
                <p className="text-sm bg-gray-100 px-4 py-2 rounded-lg font-thin">034 29 060 58</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ProfilePage;
