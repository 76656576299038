import React from "react";
import Countdown from "../components/Countdown";

const MaintenancePage = ({ timeLeft }) => {

  return (
    <div className="MaintenancePage">
      <div className="h-screen bg-dark flex flex-col justify-around items-center text-center text-white px-4">
        <div></div>
        <div className="flex flex-col space-y-8">
          <div className="container mx-auto space-y-2 uppercase font-bold">
            <h2 className="text-sm lg:text-base">Soon available</h2>
            <h1 className="text-4xl lg:text-6xl">Young For Christ</h1>
          </div>
          <div>
            <Countdown timeLeft={timeLeft} />
          </div>
        </div>
        <p>Copyright &copy; 2024 All rights reserved. Young For Christ.</p>
      </div>
    </div>
  )
};

export default MaintenancePage;
